import { Component, Input, OnInit } from '@angular/core';
import { LocalResourcesBloc } from '@kp/local-resources/local-resources.bloc';
import { Constants } from '@kp/shared/constants.service';
import { CardEventType, CardRequestType } from '@ktypes/enums';
import { CardEvent, LocalResourcesProgram } from '@ktypes/models';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kp-next-steps',
  templateUrl: './next-steps.component.html',
  styleUrls: ['./next-steps.component.scss'],
})
export class NextStepsComponent implements OnInit {
  constructor(private _localResourcesBloc: LocalResourcesBloc) {}

  @Input() card: LocalResourcesProgram;
  googleMapsDirectionsLink: string;
  feedbackUrl: string;
  cardEventType = CardEventType;

  ngOnInit() {
    this.feedbackUrl = Constants.feedbackLinkBase.replace('{{cardTitle}}', encodeURI('local' + this.card.contentId));

    this.googleMapsDirectionsLink =
      Constants.googleMapsDirectionsLinkBase +
      'destination=' +
      encodeURIComponent(
        this.card.offices?.[0]?.address1 + ' ' + this.card.offices?.[0]?.city + ' ' + this.card.offices?.[0]?.postal
      );
  }

  handleClick(cardEvent: CardEventType) {
    this._localResourcesBloc.handleCardEvents(
      new CardEvent(this.card.id, this.card, cardEvent, CardRequestType.RESOURCES)
    );
  }
}

export const MockNextStepsComponent = MockComponent({ selector: 'kp-next-steps', inputs: ['card'] });
