import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsBloc } from '@kanalytics';
import { EnvironmentVariablesService } from '@kenv';
import { DataLinkBloc } from '@kp/data-link/data-link.bloc';
import { DialogueLogicKey } from '@kp/dialogue/models/dialogue.model';
import { CardBloc } from '@kp/shared/components/cards/card.bloc';
import { Promo } from '@kp/shared/components/promo/promo.model';
import { CardRequestType } from '@ktypes/enums';
import {
  CardItem,
  ChallengeDeepLinkData,
  DeepLinkBase,
  DialogueDeepLinkData,
  Link,
  LinkType,
  QuestDeepLinkData,
  QuestKeyType,
  getTypedDeepLinkData,
} from '@ktypes/models';
import { getInAppLink, matchesInAppLink } from '@kutil';
import { MockComponent } from '@kutil/test';
import { Subject } from 'rxjs';

interface QuestQueryParams {
  questKey: string;
  questKeyType: QuestKeyType;
  questReturnUrl: string;
}

interface DialogueQueryParams {
  refKey: string;
  logicKey: string;
  returnUrl: string;
  isQuestCard: boolean;
  isPulseSurvey?: boolean;
  isExternalPulseSurvey?: boolean;
  cardRequestType: CardRequestType;
  dialogueId: string;
  promoId?: string;
}

type CTAQueryParams = Record<string, never> | QuestQueryParams | DialogueQueryParams;

interface NavigationOptions {
  route: string;
  queryParams: CTAQueryParams;
}

@Component({
  selector: 'kp-call-to-action',
  templateUrl: './call-to-action.component.html',
  styleUrls: ['./call-to-action.component.scss'],
})
export class CallToActionComponent implements OnChanges, OnDestroy {
  @Input() callToAction: string; // Note: if translation is needed, translate before passing in
  @Input() card: CardItem;
  @Input() isQuestCard = false;
  @Input() link: Link;
  @Input() promo: Promo;
  @Input() requestType: CardRequestType;
  @Input() returnUrl: string;
  @Input() showAsButton: boolean;
  @Input() showCompactView = false;

  @Output() closeCard = new EventEmitter<boolean>();
  @Output() callToActionClicked = new EventEmitter<Event>();

  private _destroy$ = new Subject<void>();

  cardLinkType = LinkType;
  deepLinkBase = DeepLinkBase;
  navigationOptions: NavigationOptions;

  constructor(
    private _analyticsBloc: AnalyticsBloc,
    private _cardBloc: CardBloc,
    private _environmentVariablesService: EnvironmentVariablesService,
    private _router: Router
  ) {}

  ngOnChanges() {
    if (this.link?.type === LinkType.INTERNAL) {
      this._setRouterLinkOptions();
    }
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  handleCardCTAClick(event: Event) {
    this.callToActionClicked.emit(event);
    const isInAppLink = matchesInAppLink(
      this.link?.uri,
      this._environmentVariablesService.config?.environment,
      this._environmentVariablesService.config?.hostname
    );
    if (
      !(
        !isInAppLink &&
        (this.link?.type === LinkType.EXTERNAL || this.link?.deepLinkBase === this.deepLinkBase.ExternalLink)
      ) &&
      ((event?.currentTarget as HTMLElement)?.tagName !== 'A' || isInAppLink || this.promo)
    ) {
      event.preventDefault();
      event.stopPropagation();

      // NOTE: This is to ensure links marked EXTERNAL that are actually to pages in app use
      // Angular routing rather than refreshing the tab or opening in a new tab
      if (isInAppLink) {
        if (!this.showCompactView && this.requestType) {
          this.closeCard.emit(true);
        }
        void this._router.navigateByUrl(getInAppLink(this.link.uri));
      }
    }
    if (this.requestType) {
      if ([DeepLinkBase.QuestionSet, DeepLinkBase.Dialogue].includes(this.link?.deepLinkBase)) {
        this._cardBloc.addCompletionCard(this.card);
      }
      // this ensures we close Detail View, won't get here if it closed it earlier
      this.closeCard.emit(true);
    }
  }

  private _setRouterLinkOptions() {
    this.navigationOptions = { route: '.', queryParams: {} };
    switch (this.link?.deepLinkBase) {
      case DeepLinkBase.Card:
        this.navigationOptions = {
          route: `./${this.link?.deepLinkData?.contentId}`,
          queryParams: {},
        };
        break;
      case DeepLinkBase.SocialChallenge:
        this.navigationOptions = {
          route: `/challenges/${(this.link?.deepLinkData as ChallengeDeepLinkData)?.socialChallengeId}`,
          queryParams: {},
        };
        break;
      case DeepLinkBase.SocialChallenges:
        this.navigationOptions = {
          route: '/challenges',
          queryParams: {},
        };
        break;
      case DeepLinkBase.Discover:
      case DeepLinkBase.Explore:
        this.navigationOptions = {
          route: '/discover',
          queryParams: {},
        };
        break;
      case DeepLinkBase.Feedback:
        this.navigationOptions = {
          route: '/feedback',
          queryParams: {},
        };
        break;
      case DeepLinkBase.Help:
        this.navigationOptions = {
          route: '/help',
          queryParams: {},
        };
        break;
      case DeepLinkBase.Invite:
      case DeepLinkBase.Sharing:
        this.navigationOptions = {
          route: '/invite',
          queryParams: {},
        };
        break;
      case DeepLinkBase.Me:
        this.navigationOptions = {
          route: '/me',
          queryParams: {},
        };
        break;
      case DeepLinkBase.MyActions:
        this.navigationOptions = {
          route: '/cards/actions',
          queryParams: {},
        };
        break;
      case DeepLinkBase.MyQuests:
        this.navigationOptions = {
          route: '/cards/quests',
          queryParams: {},
        };
        break;
      case DeepLinkBase.Quest:
        this.navigationOptions = {
          route: '/quest',
          queryParams: {
            questKey: getTypedDeepLinkData<QuestDeepLinkData>(this.link?.deepLinkData)?.refKey,
            questKeyType: QuestKeyType.refKey,
            questReturnUrl: encodeURIComponent(this._router.url),
          },
        };
        break;
      case DeepLinkBase.Notifications:
        this.navigationOptions = {
          route: '/reminders',
          queryParams: {},
        };
        break;
      case DeepLinkBase.Progress:
        this.navigationOptions = {
          route: '/progress',
          queryParams: {},
        };
        break;
      case DeepLinkBase.Report:
        this.navigationOptions = {
          route: '/report',
          queryParams: {},
        };
        break;
      case DeepLinkBase.Saved:
        this.navigationOptions = {
          route: '/cards/saved',
          queryParams: {},
        };
        break;
      case DeepLinkBase.Settings:
        this.navigationOptions = {
          route: '/settings',
          queryParams: {},
        };
        break;
      case DeepLinkBase.TakeAction:
        this.navigationOptions = {
          route: '/cards/take-action',
          queryParams: {},
        };
        break;
      case DeepLinkBase.Today:
        this.navigationOptions = {
          route: '/today',
          queryParams: {},
        };
        break;
      case DeepLinkBase.PulseSurvey: {
        const dialogueDeepLinkData = getTypedDeepLinkData<DialogueDeepLinkData>(this.link?.deepLinkData);
        this.navigationOptions = {
          route: dialogueDeepLinkData?.userDialogueId ? '/dialogue' : '/pulse_survey',
          queryParams: (dialogueDeepLinkData?.userDialogueId
            ? {
                returnUrl: dialogueDeepLinkData?.reportId
                  ? `/report/${dialogueDeepLinkData?.reportId}/new`
                  : this._router.url,
                dialogueId: dialogueDeepLinkData?.userDialogueId,
                refKey: dialogueDeepLinkData?.refKey,
                logicKey: dialogueDeepLinkData?.logicKey,
                isPulseSurvey: true,
                isExternalPulseSurvey: DataLinkBloc.isExternalPulseSurvey,
                promoId: this.promo.id,
              }
            : { promoId: this.promo.id }) as CTAQueryParams,
        };
        break;
      }
      case DeepLinkBase.QuestionSet:
      case DeepLinkBase.Dialogue: {
        const dialogueDeepLinkData = getTypedDeepLinkData<DialogueDeepLinkData>(this.link?.deepLinkData);
        this.navigationOptions = {
          route: '/dialogue',
          queryParams: {
            refKey: dialogueDeepLinkData?.refKey,
            logicKey:
              ((this.link.deepLinkData as DialogueDeepLinkData)?.logicKey as DialogueLogicKey) ||
              DialogueLogicKey.templated_question_set_dialogue,
            returnUrl: dialogueDeepLinkData?.reportId
              ? `/report/${dialogueDeepLinkData?.reportId}/new`
              : this.returnUrl || this._router.url,
            isQuestCard: this.isQuestCard || null,
            cardRequestType: this.requestType,
            dialogueId: dialogueDeepLinkData?.userDialogueId,
            promoId: this.promo?.id,
          },
        };
        break;
      }
      default:
        this.navigationOptions = {
          route: this._analyticsBloc.getDynamicPageNameIfMatch(`/${this.link.deepLinkBase.toLowerCase()}`)
            ? `/${this.link.deepLinkBase.toLowerCase()}`
            : '',
          queryParams: {},
        };
    }
  }
}

export const MockCallToActionComponent = MockComponent({
  selector: 'kp-call-to-action',
  inputs: ['callToAction', 'card', 'isQuestCard', 'link', 'promo', 'requestType', 'returnUrl', 'showCompactView'],
});
