<ng-container *transloco="let t">
  <div class="next-step-container">
    <div class="next-step-address hidden-screen">
      <p>
        <strong>{{ t('Address') }}</strong>
      </p>
      <p>{{ card.offices[0]?.address1 }}</p>
      @if (card.offices[0]?.address2) {
        <p>{{ card.offices[0]?.address2 }}</p>
      }
      <p>
        {{ card.offices[0]?.city ? card.offices[0]?.city + ', ' : false }}
        {{ card.offices[0]?.state ? card.offices[0]?.state + ' ' : false }} {{ card.offices[0]?.postal }}
      </p>
      @if (card.offices[0]?.distance && !card.offices[0]?.isAdministrative) {
        <p>{{ t('distance miles', { distance: card.offices[0]?.distance }) }}</p>
      }
    </div>
    @if (card.websiteUrl) {
      <a
        class="button button-pill button-opaque-border button-resource button-next-step-website"
        [href]="card.websiteUrl"
        target="_blank"
        (click)="handleClick(cardEventType.LOCAL_RESOURCE_NEXT_STEP_WEBSITE)">
        <svg-icon class="hidden-print" src="/assets/icons/expand.svg"></svg-icon>
        <span class="hidden-print">{{ t('Visit website') }}</span>
        <strong class="hidden-screen">{{ t('Website') }} -</strong>
      </a>
    }
    @if (card.offices[0]?.phoneNumber) {
      <a
        class="button button-pill button-opaque-border button-resource button-phone-number"
        [href]="
          card.offices[0]?.phoneNumber?.phoneNumberWithoutExtension
            | clickableTelephoneNumber: card.offices[0]?.phoneNumber?.extension
        "
        (click)="handleClick(cardEventType.LOCAL_RESOURCE_NEXT_STEP_PHONE)">
        <span class="phone">
          <svg-icon class="hidden-print" src="/assets/icons/call.svg"></svg-icon>
          <strong class="hidden-screen">{{ t('Phone') }} -</strong>
          <span>{{ card.offices[0]?.phoneNumber?.phoneNumberWithoutExtension }}</span>
        </span>
        @if (card.offices[0]?.phoneNumber?.extension) {
          <span>ext. {{ card.offices[0]?.phoneNumber?.extension }}</span>
        }
      </a>
    }
    @if (card.offices[0]?.address1 && !card.offices[0]?.isAdministrative) {
      <a
        class="button button-pill button-opaque-border button-resource hidden-print"
        [href]="googleMapsDirectionsLink"
        target="_blank"
        (click)="handleClick(cardEventType.LOCAL_RESOURCE_NEXT_STEP_DIRECTIONS)">
        <svg-icon src="/assets/icons/directions.svg"></svg-icon>
        <span>{{ t('Get directions') }}</span>
      </a>
    }
    @if (card.offices[0]?.email) {
      <a
        class="button button-pill button-opaque-border button-resource button-next-step-email"
        [href]="card.offices[0]?.email | emailLink: 'Response Requested: Seeking Program Information'"
        (click)="handleClick(cardEventType.LOCAL_RESOURCE_NEXT_STEP_EMAIL)">
        <strong class="hidden-screen">{{ t('Email') }} -</strong>
        <svg-icon class="hidden-print" src="/assets/icons/mail.svg"></svg-icon>
        <span class="hidden-print">{{ t('Send an email') }}</span>
        <span class="hidden-screen">&nbsp;{{ card.offices[0]?.email }}</span>
      </a>
    }
  </div>

  <div class="next-step-container mobile">
    @if (card.websiteUrl) {
      <a
        class="button-resource-mobile"
        [href]="card.websiteUrl"
        target="_blank"
        (click)="handleClick(cardEventType.LOCAL_RESOURCE_NEXT_STEP_WEBSITE)">
        <svg-icon src="/assets/icons/globe.svg"></svg-icon>
        <span>{{ t('Website') }}</span>
      </a>
    }
    @if (card.offices[0]?.address1 && !card.offices[0]?.isAdministrative) {
      <a
        class="button-resource-mobile"
        [href]="googleMapsDirectionsLink"
        target="_blank"
        (click)="handleClick(cardEventType.LOCAL_RESOURCE_NEXT_STEP_DIRECTIONS)">
        <svg-icon src="/assets/icons/directions.svg"></svg-icon>
        <span>{{ t('Directions') }}</span>
      </a>
    }
    @if (card.offices[0]?.phoneNumber) {
      <a
        class="button-resource-mobile"
        [href]="
          card.offices[0]?.phoneNumber?.phoneNumberWithoutExtension
            | clickableTelephoneNumber: card.offices[0]?.phoneNumber?.extension
        "
        (click)="handleClick(cardEventType.LOCAL_RESOURCE_NEXT_STEP_PHONE)">
        <svg-icon src="/assets/icons/call.svg"></svg-icon>
        <span>{{ t('Call') }}</span>
      </a>
    }
    @if (card.offices[0]?.email) {
      <a
        class="button-resource-mobile"
        [href]="card.offices[0]?.email | emailLink: 'Response Requested: Seeking Program Information'"
        (click)="handleClick(cardEventType.LOCAL_RESOURCE_NEXT_STEP_EMAIL)">
        <svg-icon src="/assets/icons/mail.svg"></svg-icon>
        <span>{{ t('Email') }}</span>
      </a>
    }
  </div>
</ng-container>
