import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslocoDirective } from '@jsverse/transloco';
import { FocusComponent } from '@kp/focus/components/focus/focus.component';
import { InsightListComponent } from '@kp/insights/components/insight-list/insight-list.component';
import { ModalTrendingComponent } from '@kp/insights/components/modal-trending/modal-trending.component';
import { TrendArrowComponent } from '@kp/insights/components/trend-arrow/trend-arrow.component';
import { PurposefulDayIndicatorComponent } from '@kp/purposeful-day/purposeful-day-indicator/purposeful-day-indicator.component';
import { TextFieldComponent } from '@kp/question-set/answer/text-field/text-field.component';
import { PermissionDialogComponent } from '@kp/screen-reminders/components/permission-dialog/permission-dialog.component';
import { ModalTimePickerComponent } from '@kp/screen-reminders/modals/modal-time-picker/modal-time-picker.component';
import { AccountCreationBannerComponent } from '@kp/shared/components/account-creation-banner/account-creation-banner.component';
import { AccountCreationModalComponent } from '@kp/shared/components/account-creation-banner/modals/account-creation-modal/account-creation-modal.component';
import { ActionImageComponent } from '@kp/shared/components/action-image/action-image.component';
import { ActionReminderDetailedViewComponent } from '@kp/shared/components/action-reminders/action-reminder-detailed-view/action-reminder-detailed-view.component';
import { ActionReminderDiamondComponent } from '@kp/shared/components/action-reminders/action-reminder-diamond/action-reminder-diamond.component';
import { ActionReminderTimeSelectorComponent } from '@kp/shared/components/action-reminders/action-reminder-time-selector/action-reminder-time-selector.component';
import { ActionReminderComponent } from '@kp/shared/components/action-reminders/action-reminder/action-reminder.component';
import { HabitPairingComponent } from '@kp/shared/components/action-reminders/habit-pairing/habit-pairing.component';
import { ActAnimationComponent } from '@kp/shared/components/animations/act-animation/act-animation.component';
import { AiCoachAnimationComponent } from '@kp/shared/components/animations/ai-coach-animation/ai-coach-animation.component';
import { AnimationCardComponent } from '@kp/shared/components/animations/animation-card/animation-card.component';
import { DiscoverAnimationComponent } from '@kp/shared/components/animations/discover-animation/discover-animation.component';
import { DoneAnimationComponent } from '@kp/shared/components/animations/done-animation/done-animation.component';
import { LoadingAnimationComponent } from '@kp/shared/components/animations/loading-animation/loading-animation.component';
import { ProgressAnimationComponent } from '@kp/shared/components/animations/progress-animation/progress-animation.component';
import { ReflectAnimationComponent } from '@kp/shared/components/animations/reflect-animation/reflect-animation.component';
import { SimpleIconAnimationComponent } from '@kp/shared/components/animations/simple-icon-animation/simple-icon-animation.component';
import { SplashAnimationComponent } from '@kp/shared/components/animations/splash-animation/splash-animation.component';
import { BottomArrowNavComponent } from '@kp/shared/components/bottom-arrow-nav/bottom-arrow-nav.component';
import { BrandingIconComponent } from '@kp/shared/components/branding-icon/branding-icon.component';
import { CallToActionComponent } from '@kp/shared/components/cards/call-to-action/call-to-action.component';
import { CardActionsComponent } from '@kp/shared/components/cards/card-actions/card-actions.component';
import { CardCategoryComponent } from '@kp/shared/components/cards/card-category/card-category.component';
import { CardListComponent } from '@kp/shared/components/cards/card-list/card-list.component';
import { CardTotdComponent } from '@kp/shared/components/cards/card-totd/card-totd.component';
import { CardComponent } from '@kp/shared/components/cards/card/card.component';
import { DetailCardViewComponent } from '@kp/shared/components/cards/detail-card-view/detail-card-view.component';
import { DetailViewActionComponent } from '@kp/shared/components/cards/detail-card-view/detail-view-action/detail-view-action.component';
import { CarouselComponent } from '@kp/shared/components/carousel/carousel.component';
import { CopyrightComponent } from '@kp/shared/components/copyright/copyright.component';
import { CoreNavImageComponent } from '@kp/shared/components/core-nav-image/core-nav-image.component';
import { CoreNavComponent } from '@kp/shared/components/core-nav/core-nav.component';
import { DashesComponent } from '@kp/shared/components/dashes/dashes.component';
import { DetailCardLoaderComponent } from '@kp/shared/components/detail-card-loader/detail-card-loader.component';
import { DetailCardUnloaderComponent } from '@kp/shared/components/detail-card-unloader/detail-card-unloader.component';
import { DiamondComponent } from '@kp/shared/components/diamond/diamond.component';
import { FeatureFilterComponent } from '@kp/shared/components/feature-filter/feature-filter.component';
import { FeedbackButtonsComponent } from '@kp/shared/components/feedback-buttons/feedback-buttons.component';
import { FeedbackComponent } from '@kp/shared/components/feedback/feedback.component';
import { FirstCardAddedComponent } from '@kp/shared/components/first-card-added-modal/first-card-added-modal.component';
import { FirstReflectionCompletedComponent } from '@kp/shared/components/first-reflection-completed-modal/first-reflection-completed-modal.component';
import { HelpComponent } from '@kp/shared/components/help/help.component';
import { InputOptionComponent } from '@kp/shared/components/input-option/input-option.component';
import { LikeIconComponent } from '@kp/shared/components/like-icon/like-icon.component';
import { LiveSupportComponent } from '@kp/shared/components/live-support/live-support.component';
import { LoadingBarComponent } from '@kp/shared/components/loading-bar/loading-bar.component';
import { MobilePromoComponent } from '@kp/shared/components/mobile-promo/mobile-promo.component';
import { ModalAccountDeletionComponent } from '@kp/shared/components/modal-account-deletion/modal-account-deletion.component';
import { ModalAppSwitcherPopoverComponent } from '@kp/shared/components/modal-app-switcher-popover/modal-app-switcher-popover.component';
import { ModalCrossPromotionComponent } from '@kp/shared/components/modal-cross-promotion/modal-cross-promotion.component';
import { ModalCustomizeNotificationsComponent } from '@kp/shared/components/modal-customize-notifications/modal-customize-notifications.component';
import { ModalEnableNotificationsComponent } from '@kp/shared/components/modal-enable-notifications/modal-enable-notifications.component';
import { ModalFeedbackComponent } from '@kp/shared/components/modal-feedback/modal-feedback.component';
import { ModalPasswordResetRequiredComponent } from '@kp/shared/components/modal-password-reset-required/modal-password-reset-required.component';
import { ModalPoliciesComponent } from '@kp/shared/components/modal-policies/modal-policies.component';
import { IntroScreenComponent } from '@kp/shared/components/privacy-full/intro-screen/intro-screen.component';
import { PrivacyFullComponent } from '@kp/shared/components/privacy-full/privacy-full.component';
import { ProgressBarComponent } from '@kp/shared/components/progress-bar/progress-bar.component';
import { PromoListComponent } from '@kp/shared/components/promo/components/promo-list/promo-list.component';
import { PromoComponent } from '@kp/shared/components/promo/components/promo/promo.component';
import { QuickActionsComponent } from '@kp/shared/components/quick-actions/quick-actions.component';
import { SaveIconComponent } from '@kp/shared/components/save-icon/save-icon.component';
import { ShimmerComponent } from '@kp/shared/components/shimmer/shimmer.component';
import { SiteBasicLogoHeaderComponent } from '@kp/shared/components/site-basic-logo-header/site-basic-logo-header.component';
import { SiteFooterComponent } from '@kp/shared/components/site-footer/site-footer.component';
import { SiteHeaderComponent } from '@kp/shared/components/site-header/site-header.component';
import { SiteNavComponent } from '@kp/shared/components/site-nav/site-nav.component';
import { SmileyRowComponent } from '@kp/shared/components/smiley-row/smiley-row.component';
import { SwitchComponent } from '@kp/shared/components/switch/switch.component';
import { TooltipComponent } from '@kp/shared/components/tooltip/tooltip.component';
import { TopArrowNavComponent } from '@kp/shared/components/top-arrow-nav/top-arrow-nav.component';
import { WordCloudComponent } from '@kp/shared/components/word-cloud/word-cloud.component';
import { ZipCodeComponent } from '@kp/shared/components/zip-code/zip-code.component';
import { Constants } from '@kp/shared/constants.service';
import { ModalCropImageComponent } from '@kp/shared/image/components/modal-crop-image/modal-crop-image.component';
import { PhotoAddComponent } from '@kp/shared/image/components/photo-add/photo-add.component';
import { PhotoCarouselComponent } from '@kp/shared/image/components/photo-carousel/photo-carousel.component';
import { PhotoWithCarouselComponent } from '@kp/shared/image/components/photo-with-carousel/photo-with-carousel.component';
import { PhotoComponent } from '@kp/shared/image/components/photo/photo.component';
import { SafeAddImageWidth } from '@kp/shared/image/components/photo/safe-add-image-width.pipe';
import { AnswerValuePipe } from '@kp/shared/pipes/answer-value.pipe';
import { GetProductPipe } from '@kp/shared/pipes/get-product.pipe';
import { BrowserStorage, ServiceModule } from '@kservice';
import {
  A11yLiveAnnounceComponent,
  AiCoachLabelComponent,
  CheckboxComponent,
  UiModule,
  VersionTagComponent,
} from '@kui';
import { InViewportModule } from '@kumanu/angular-inviewport';
import { Popup, UtilsModule } from '@kutil';
import { TippyDirective } from '@ngneat/helipopper';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { QrCodeComponent, QrCodeModule } from 'ng-qrcode';
import { RIVE_FOLDER, RIVE_WASM, RiveModule } from 'ng-rive';
import { DragScrollComponent, DragScrollItemDirective } from 'ngx-drag-scroll';
import { ImageCropperComponent } from 'ngx-image-cropper';

@NgModule({
  declarations: [
    AccountCreationBannerComponent,
    AccountCreationModalComponent,
    ActAnimationComponent,
    ActionImageComponent,
    ActionReminderComponent,
    ActionReminderDetailedViewComponent,
    ActionReminderDiamondComponent,
    ActionReminderTimeSelectorComponent,
    AiCoachAnimationComponent,
    AnswerValuePipe,
    AnimationCardComponent,
    BottomArrowNavComponent,
    BrandingIconComponent,
    CallToActionComponent,
    CardActionsComponent,
    CardCategoryComponent,
    CardComponent,
    CardListComponent,
    CardTotdComponent,
    CarouselComponent,
    CopyrightComponent,
    CoreNavComponent,
    CoreNavImageComponent,
    DashesComponent,
    DetailCardLoaderComponent,
    DetailCardUnloaderComponent,
    DetailCardViewComponent,
    DetailViewActionComponent,
    DiamondComponent,
    DoneAnimationComponent,
    DiscoverAnimationComponent,
    FeatureFilterComponent,
    FeedbackButtonsComponent,
    FeedbackComponent,
    FirstCardAddedComponent,
    FirstReflectionCompletedComponent,
    FocusComponent,
    GetProductPipe,
    HabitPairingComponent,
    HelpComponent,
    InputOptionComponent,
    InsightListComponent,
    IntroScreenComponent,
    LikeIconComponent,
    LiveSupportComponent,
    LoadingAnimationComponent,
    LoadingBarComponent,
    ModalCropImageComponent,
    MobilePromoComponent,
    ModalAccountDeletionComponent,
    ModalAppSwitcherPopoverComponent,
    ModalCrossPromotionComponent,
    ModalCustomizeNotificationsComponent,
    ModalFeedbackComponent,
    ModalPasswordResetRequiredComponent,
    ModalPoliciesComponent,
    ModalEnableNotificationsComponent,
    ModalTimePickerComponent,
    ModalTrendingComponent,
    PermissionDialogComponent,
    PhotoAddComponent,
    PhotoComponent,
    PhotoCarouselComponent,
    PhotoWithCarouselComponent,
    PrivacyFullComponent,
    ProgressAnimationComponent,
    ProgressBarComponent,
    PromoComponent,
    PromoListComponent,
    QuickActionsComponent,
    ReflectAnimationComponent,
    SafeAddImageWidth,
    SaveIconComponent,
    ShimmerComponent,
    SimpleIconAnimationComponent,
    SiteBasicLogoHeaderComponent,
    SiteFooterComponent,
    SiteHeaderComponent,
    SiteNavComponent,
    SmileyRowComponent,
    SplashAnimationComponent,
    SwitchComponent,
    TextFieldComponent,
    TooltipComponent,
    TopArrowNavComponent,
    TrendArrowComponent,
    WordCloudComponent,
    ZipCodeComponent,
  ],
  imports: [
    A11yLiveAnnounceComponent,
    AiCoachLabelComponent,
    AngularSvgIconModule.forRoot(),
    CommonModule,
    CheckboxComponent,
    DragScrollComponent,
    DragScrollItemDirective,
    FormsModule,
    HttpClientModule,
    ImageCropperComponent,
    InViewportModule,
    PurposefulDayIndicatorComponent,
    QrCodeModule,
    ReactiveFormsModule,
    RiveModule,
    RouterModule,
    ServiceModule,
    TippyDirective,
    UiModule,
    UtilsModule,
    VersionTagComponent,
    TranslocoDirective,
  ],
  exports: [
    A11yLiveAnnounceComponent,
    AccountCreationBannerComponent,
    AccountCreationModalComponent,
    ActionImageComponent,
    ActAnimationComponent,
    AiCoachAnimationComponent,
    AiCoachLabelComponent,
    AngularSvgIconModule,
    AnimationCardComponent,
    AnswerValuePipe,
    BottomArrowNavComponent,
    BrandingIconComponent,
    CallToActionComponent,
    CardCategoryComponent,
    CardComponent,
    CardListComponent,
    CardTotdComponent,
    CarouselComponent,
    CheckboxComponent,
    CopyrightComponent,
    CoreNavComponent,
    CoreNavImageComponent,
    DetailCardLoaderComponent,
    DetailCardUnloaderComponent,
    DetailCardViewComponent,
    DiamondComponent,
    DoneAnimationComponent,
    DiscoverAnimationComponent,
    FeatureFilterComponent,
    FeedbackButtonsComponent,
    FeedbackComponent,
    FirstCardAddedComponent,
    FirstReflectionCompletedComponent,
    FocusComponent,
    GetProductPipe,
    HelpComponent,
    HttpClientModule,
    InputOptionComponent,
    InsightListComponent,
    IntroScreenComponent,
    InViewportModule,
    LiveSupportComponent,
    LoadingBarComponent,
    LoadingAnimationComponent,
    ModalCropImageComponent,
    MobilePromoComponent,
    ModalAccountDeletionComponent,
    ModalAppSwitcherPopoverComponent,
    ModalCrossPromotionComponent,
    ModalCustomizeNotificationsComponent,
    ModalFeedbackComponent,
    ModalPasswordResetRequiredComponent,
    ModalPoliciesComponent,
    ModalEnableNotificationsComponent,
    ModalTimePickerComponent,
    ModalTrendingComponent,
    QrCodeComponent,
    PermissionDialogComponent,
    PhotoAddComponent,
    PhotoComponent,
    PhotoCarouselComponent,
    PhotoWithCarouselComponent,
    ProgressBarComponent,
    PromoListComponent,
    ReflectAnimationComponent,
    PrivacyFullComponent,
    ProgressAnimationComponent,
    PromoComponent,
    RiveModule,
    RouterModule,
    SafeAddImageWidth,
    SaveIconComponent,
    ServiceModule,
    ShimmerComponent,
    SimpleIconAnimationComponent,
    SiteBasicLogoHeaderComponent,
    SiteFooterComponent,
    SiteHeaderComponent,
    SiteNavComponent,
    SmileyRowComponent,
    SplashAnimationComponent,
    SwitchComponent,
    TextFieldComponent,
    TippyDirective,
    TopArrowNavComponent,
    TrendArrowComponent,
    UiModule,
    UtilsModule,
    VersionTagComponent,
    WordCloudComponent,
    ZipCodeComponent,
  ],
  providers: [
    BrowserStorage,
    Constants,
    Popup,
    {
      provide: RIVE_WASM,
      useValue: 'assets/rive/rive.wasm',
    },
    {
      provide: RIVE_FOLDER,
      useValue: 'assets/rive',
    },
  ],
})
export class SharedModule {}
