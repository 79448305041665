import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AnalyticEvent, AnalyticsData, AnalyticsPageName } from '@kanalytics';
import { AuthenticationBloc } from '@kp/auth/authentication.bloc';
import { DialogueBloc } from '@kp/dialogue/dialogue.bloc';
import { PulseSurveyBloc } from '@kp/pulse-survey/pulse-survey.bloc';
import { Constants } from '@kp/shared/constants.service';
import { GlobalElementsService } from '@kp/shared/services/global-elements.service';
import { DataStoreService, ThemeService, productDisplayName } from '@kservice';
import { Status } from '@ktypes/models';
import { MockComponent } from '@kutil/test';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'kp-site-basic-logo-header',
  templateUrl: './site-basic-logo-header.component.html',
  styleUrls: ['./site-basic-logo-header.component.scss'],
})
export class SiteBasicLogoHeaderComponent implements OnInit, OnDestroy {
  analyticsData: AnalyticsData;
  analyticEvent = AnalyticEvent;
  logoUrl: string;
  showHelpModal = false;
  showFeedbackModal = false;
  routesNotToNavigateFrom = [
    'dialogue',
    'resources',
    'privacy-pledge',
    'pulse_survey',
    'question_set',
    'report',
    'thank_you',
  ];
  productName = productDisplayName;
  constants = Constants;
  trendUpButton: HTMLElement;

  showFeedback: HTMLElement;
  @ViewChild('showFeedbackRef') set contentFeedback(value: ElementRef) {
    if (value) {
      this.showFeedback = value.nativeElement as HTMLElement;
    }
  }
  showHelp: HTMLElement;
  @ViewChild('showHelpRef') set contentHelp(value: ElementRef) {
    if (value) {
      this.showHelp = value.nativeElement as HTMLElement;
    }
  }

  private _destroy$ = new Subject<void>();

  protected readonly Status = Status;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _authBloc: AuthenticationBloc,
    public dataStoreService: DataStoreService,
    public dialogueBloc: DialogueBloc,
    @Inject(DOCUMENT) private _document: Document,
    public globalElementsService: GlobalElementsService,
    private _pulseSurveyBloc: PulseSurveyBloc,
    private _renderer: Renderer2,
    public themeService: ThemeService
  ) {}

  ngOnInit() {
    this.logoUrl = this._letLogoNavigate() ? '/' : null;
    combineLatest([this._pulseSurveyBloc.pulseSurveySubject$, this.dialogueBloc.dialogue$])
      .pipe(takeUntil(this._destroy$))
      .subscribe(([pulseSurveyStatus, dialogueStatus]) => {
        if (dialogueStatus?.status === Status.done && dialogueStatus?.data) {
          this.analyticsData = {
            page: AnalyticsPageName.dialogue,
            meta: {
              logicKey: dialogueStatus.data?.logicKey,
              dialogueId: dialogueStatus.data?.id,
              questionId: dialogueStatus.data?.dialogueBlock?.question?.id,
            },
          };
        } else if (pulseSurveyStatus?.status === Status.done && pulseSurveyStatus?.data) {
          this.analyticsData = {
            page: AnalyticsPageName.pulseSurveyIntroScreen,
            meta: {
              surveyId: pulseSurveyStatus?.data?.id,
            },
          };
        }
      });
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  closeHelpModalIfOpen() {
    this._renderer.removeClass(this._document.documentElement, 'no-scroll');
    this.showHelpModal = false;
  }

  closeFeedbackModalIfOpen() {
    this._renderer.removeClass(this._document.documentElement, 'no-scroll');
    this.showFeedbackModal = false;
  }

  urlHasMatch(nonNavigableRoutes: string[], url: string) {
    if (Array.isArray(nonNavigableRoutes) && url) {
      return nonNavigableRoutes.some((nonNavigableRoute) => url.match(RegExp(nonNavigableRoute)));
    }
    return false;
  }

  private _letLogoNavigate() {
    const url = this._activatedRoute.snapshot.firstChild.url.toString();
    return this._authBloc.isLoggedIn() || !this.urlHasMatch(this.routesNotToNavigateFrom, url);
  }
}

export const MockSiteBasicLogoHeaderComponent = MockComponent({
  selector: 'kp-site-basic-logo-header',
});
