<ng-container *transloco="let t">
  <a
    routerLink="/purposeful-days"
    class="purposeful-days-link"
    [attr.aria-label]="
      t('Purposeful Days.') +
      ' ' +
      (complete ? t('Completed!') : t('Not completed')) +
      (streakLength > 0 ? ' ' + t('Purposeful Day Streak') + ' ' + streakLength : '')
    "
    (click)="purposefulDaysClicked.emit()">
    <div class="purposeful-day-indicator" [ngClass]="{ 'purposeful-day-completed': complete }">
      <div class="purposeful-day-leaf">
        <svg-icon src="/assets/icons/leaf.svg"></svg-icon>
        <span class="streak-number">{{ streakLength }}</span>
      </div>
    </div>
  </a>

  @if (showCelebrationModal) {
    <kui-modal
      [isOpen]="showCelebrationModal"
      (isOpenChange)="isCelebrationModalOpen()"
      (click)="showCelebrationModal = false"
      [canDismiss]="false"
      type="info">
      <kp-modal-purposeful-day (closeClicked)="showCelebrationModal = false"></kp-modal-purposeful-day>
    </kui-modal>
  }
</ng-container>
