import { DateTimeUtil } from '@kutil';
import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';

export interface LocalResourceDay {
  key?: string;
  name?: string;
  isOpen?: boolean;
  allDay?: boolean;
  finish?: string;
  start?: string;
}

export class LocalResourceHours implements Deserializable<JsonObject, LocalResourceHours> {
  constructor(
    public days?: LocalResourceDay[],
    public timezone?: string,
    public timezoneName?: string
  ) {}

  deserialize(input: JsonObject): LocalResourceHours {
    if (input == null) {
      return null;
    }

    this.days = DateTimeUtil.nonLocalizedAllDays().map((dayName, index) => ({
      key: dayName,
      name: DateTimeUtil.allDays()[index],
      isOpen: input[dayName] as boolean,
      allDay: input[`${dayName}_all_day`] as boolean,
      finish: input[`${dayName}_finish`] ? (input[`${dayName}_finish`] as string).replace(/(^0)|\s/g, '') : null,
      start: input[`${dayName}_start`] ? (input[`${dayName}_start`] as string).replace(/(^0)|\s/g, '') : null,
    }));
    this.timezone = input['timezone'] as string;
    this.timezoneName = input['timezone_name'] as string;

    return this;
  }
}
