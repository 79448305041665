<ng-container *transloco="let t">
  <button
    [attr.aria-label]="
      t('Like Button') +
      ' ' +
      (liked ? t('liked') : '') +
      (likeCount > 0 ? ' ' + t('Like count') + ' ' + likeCount : '')
    "
    (click)="likeButtonClicked($event)"
    class="icon-action">
    <div class="like-container">
      <kp-simple-icon-animation
        [ngClass]="{ liked }"
        [options]="options"
        [iconShouldFill]="liked"
        [iconClicked]="likeClicked"
        [color]="color"></kp-simple-icon-animation>
      @if (likeCount > 0) {
        <span class="like-count" aria-hidden="true" [ngStyle]="{ color: color }">
          {{ likeCount }}
        </span>
      }
    </div>
  </button>
</ng-container>
