<ng-container *transloco="let t">
  <div class="hours">
    <svg-icon src="/assets/icons/clock.svg" class="hidden-print" [ngClass]="{ closed: !isOpenToday }"></svg-icon>
    @if (isOpenToday) {
      <h4 class="title-hours today hidden-print">
        {{ t('Open Today') }} &middot; {{ todayStartTime }} - {{ todayFinishTime }}
      </h4>
    }
    @if (!isOpenToday) {
      <h4 class="title-hours closed hidden-print">{{ t('Closed Today') }}</h4>
    }
    <h4 class="title-hours hidden-screen">{{ t('Hours') }}</h4>

    <ul class="hours-list">
      @for (day of hours.days; track day) {
        <li>
          {{ DateTimeUtil.weekDaysData()[day.name]?.initial | uppercase }}:
          @if (day.isOpen) {
            <span>{{ day.start | lowercase }} - {{ day.finish | lowercase }}</span>
          } @else {
            {{ t('Closed') }}
          }
        </li>
      }
    </ul>
  </div>
</ng-container>
