<ng-container *transloco="let t">
  <div class="time-picker-container">
    @if (timeOfDay) {
      <div class="title">
        <svg-icon class="icon" [src]="svgURL"></svg-icon>
        {{ title ? title : t('timeOfDay Reminders', { timeOfDay }) }}
      </div>
    }
    <div class="picker">
      <div class="select-time">
        <button class="time-adjust" [attr.aria-label]="t('Increment hour')" (click)="adjustTime(timeProperty.hour, 1)">
          <svg-icon src="/assets/icons/chevron-left-nocircle.svg"></svg-icon>
        </button>
        @if (showHourInput) {
          <input
            class="time-input"
            #hourInput
            type="number"
            [value]="hour"
            [min]="minHour"
            [max]="maxHour"
            (blur)="inputTime(timeProperty.hour, hourInput.value)"
            (keyup.enter)="inputTime(timeProperty.hour, hourInput.value)" />
        } @else {
          <button
            class="time-adjust"
            [attr.aria-label]="t('Edit hour')"
            (click)="showTimePropertyInput(timeProperty.hour)">
            {{ hour }}
          </button>
        }
        <button class="time-adjust" [attr.aria-label]="t('Decrement hour')" (click)="adjustTime(timeProperty.hour, -1)">
          <svg-icon src="/assets/icons/chevron-right-nocircle.svg"></svg-icon>
        </button>
      </div>
      <div class="select-time">:</div>
      <div class="select-time">
        <button
          class="time-adjust"
          [attr.aria-label]="t('Increment minutes (15 minute increments)')"
          (click)="adjustTime(timeProperty.minutes, 1)">
          <svg-icon src="/assets/icons/chevron-left-nocircle.svg"></svg-icon>
        </button>
        @if (showMinInput) {
          <input
            class="time-input"
            #minutesInput
            type="number"
            [value]="minutes"
            min="00"
            max="45"
            step="15"
            (blur)="inputTime(timeProperty.minutes, minutesInput.value)"
            (keyup.enter)="inputTime(timeProperty.minutes, minutesInput.value)" />
        } @else {
          <button
            class="time-adjust"
            [attr.aria-label]="t('Edit minutes (15 minute increments)')"
            (click)="showTimePropertyInput(timeProperty.minutes)">
            {{ minutes }}
          </button>
        }
        <button
          class="time-adjust"
          [attr.aria-label]="t('Decrement minutes (15 minute increments)')"
          (click)="adjustTime(timeProperty.minutes, -1)">
          <svg-icon src="/assets/icons/chevron-right-nocircle.svg"></svg-icon>
        </button>
      </div>
      @if (!is24HrFormat) {
        <div class="select-time">
          <button class="time-adjust" [attr.aria-label]="t('Toggle AM PM')" (click)="adjustTime(timeProperty.amPM, 1)">
            <svg-icon src="/assets/icons/chevron-left-nocircle.svg"></svg-icon>
          </button>
          <button class="time-adjust" [attr.aria-label]="t('Toggle AM PM')" (click)="adjustTime(timeProperty.amPM, 1)">
            {{ AMPM }}
          </button>
          <button class="time-adjust" [attr.aria-label]="t('Toggle AM PM')" (click)="adjustTime(timeProperty.amPM, -1)">
            <svg-icon src="/assets/icons/chevron-right-nocircle.svg"></svg-icon>
          </button>
        </div>
      }
      <kui-a11y-live-announce [announcement]="timeChangeAnnouncement"></kui-a11y-live-announce>
    </div>
    @if (showClose || showSave) {
      <div class="button-row">
        @if (showClose) {
          <button class="button-cancel" (click)="closeClicked.emit()">{{ t('Close') }}</button>
        }
        @if (showSave) {
          <button
            class="button button-reverse button-pill button-save"
            [attr.aria-label]="
              t('Save reminder time time', {
                time: hour + ':' + minutes + (is24HrFormat ? '' : ' ' + AMPM)
              })
            "
            (click)="save()">
            {{ submitText ? submitText : t('Save') }}
          </button>
        }
      </div>
    }
  </div>
</ng-container>
