import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'clickableTelephoneNumber',
})
export class ClickableTelephoneNumberPipe implements PipeTransform {
  transform(value: string, extension: string = null, sms = false): string {
    return `${sms ? 'sms:' : 'tel:'}${value}${extension ? ';' + extension : ''}`;
  }
}
