import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DateTimeUtil } from '@kutil';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kp-action-reminder-diamond',
  templateUrl: './action-reminder-diamond.component.html',
  styleUrls: ['./action-reminder-diamond.component.scss'],
})
export class ActionReminderDiamondComponent {
  @Input() day: string;
  @Input() isSelected?: boolean;

  protected readonly DateTimeUtil = DateTimeUtil;

  @Output() reminderDay = new EventEmitter<string>();
}

export const MockActionReminderDiamondComponent = MockComponent({
  selector: 'kp-action-reminder-diamond',
  inputs: ['day', 'isSelected'],
});
