@if (!isShortText) {
  <textarea
    #input
    autoFocus
    id="long-text"
    [maxLength]="maxCharacters"
    wrap="soft"
    placeholder="{{ placeholderText }}"
    [(ngModel)]="textValue"
    (input)="onTyping(input.value)"
    data-e2e="textField"></textarea>
  @if (textValue != null && maxCharacters > 0) {
    <div class="character-count">
      <span>{{ textValue.length }} / {{ maxCharacters }}</span>
    </div>
  }
} @else {
  <input
    #input
    type="text"
    autoFocus
    [maxLength]="maxCharacters"
    (input)="onTyping(input.value)"
    [(ngModel)]="textValue"
    placeholder="{{ placeholderText }}"
    data-e2e="textField" />
}
