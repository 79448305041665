import { DOCUMENT, Location } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AnalyticEvent, AnalyticsBloc } from '@kanalytics';
import { TagBloc } from '@kbloc';
import { DialogueLogicKey } from '@kp/dialogue/models/dialogue.model';
import { Constants, QuickAction } from '@kp/shared/constants.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'kp-quick-actions',
  templateUrl: './quick-actions.component.html',
  styleUrls: ['./quick-actions.component.scss'],
})
export class QuickActionsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() quickActionsOpen = false;
  @Output() quickActionsToggled = new EventEmitter<boolean>();

  private _preventAnnouncement = false;
  quickActions: QuickAction[];
  quickActionsAnnouncement = '';

  @HostListener('window:keydown.escape', ['$event']) private _triggerEsc() {
    if (this.quickActionsOpen) {
      this.toggleQuickActions();
    }
  }

  private _coachEnabled: boolean;
  private _destroy$ = new Subject<void>();

  // Using Location because Router events are not triggered on first route load
  constructor(
    private _analyticsBloc: AnalyticsBloc,
    @Inject(DOCUMENT) private _document: Document,
    private _location: Location,
    private _tagBloc: TagBloc
  ) {}

  ngOnInit() {
    // Set returnUrl on initial load
    this._updateAndFilterQuickActions();

    // Set returnUrl on url change
    this._location.onUrlChange(() => {
      this._updateAndFilterQuickActions();
    });

    this._tagBloc.exploratoryGenerativeAIEnabled$
      .pipe(takeUntil(this._destroy$))
      .subscribe((exploratoryGenerativeAIEnabled) => {
        this._coachEnabled = exploratoryGenerativeAIEnabled;
        this._updateAndFilterQuickActions();
      });
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this._preventAnnouncement && changes['quickActionsOpen'] != null && !changes['quickActionsOpen'].firstChange) {
      /**
       * t(Opening Quick Actions Menu)
       * t(Closing Quick Actions Menu)
       */
      this.quickActionsAnnouncement = `${this.quickActionsOpen ? 'Opening' : 'Closing'} Quick Actions Menu`;
    }
  }

  toggleQuickActions(quickAction?: QuickAction, event?: Event) {
    if (event && event.type !== 'click') {
      (event.target as HTMLElement).click();
      return;
    }

    if (quickAction) {
      this._analyticsBloc.sendEvent(
        this._analyticsBloc.createEventFromInteraction({
          page: this._analyticsBloc.getDynamicPageNameIfMatch(),
          event: AnalyticEvent.quick_action_selected,
          meta: {
            ...quickAction.queryParams,
            text: quickAction.text,
          },
        })
      );

      if (quickAction.queryParams?.action === 'add_photo') {
        setTimeout(() => {
          // this needs to be executed as part of a user initiated action, so looking up the clickable
          // element and clicking it as part of the click for the quick action
          (this._document.querySelector('[data-selector="file-input"]') as HTMLElement)?.click();
        }, 300);
      }
    }
    this._preventAnnouncement = !!quickAction; // prevent announcement when quickAction is passed (i.e. navigation)
    this.quickActionsOpen = !this.quickActionsOpen;
    this.quickActionsToggled.emit(this.quickActionsOpen);
  }

  private _updateAndFilterQuickActions() {
    this.quickActions = Constants.quickActions
      .filter((action) => !(action.queryParams.logicKey === DialogueLogicKey.gpt_coach_checkin && !this._coachEnabled))
      .map((action) => {
        if (action.returnUrl) {
          return {
            ...action,
            queryParams: {
              ...action.queryParams,
              returnUrl: typeof action.returnUrl === 'string' ? action.returnUrl : this._location.path(),
            },
          };
        }
        return action;
      });
  }
}
