<ng-container *transloco="let t">
  @if (localResourcesBloc.localResourceDetails$ | async; as localResource) {
    <div class="card-container">
      <header class="detail-view-header">
        @if (localResource?.status === status.done) {
          <kp-feedback-buttons
            class="hidden-print"
            [preference]="localResource?.data?.userState.preference"
            [screen]="screen"
            [feedbackTitleInfo]="'local ' + localResource?.data?.id"
            [isLocalResource]="true"
            [card]="localResource?.data"
            (feedbackClicked)="moreOrLess($event, localResource?.data)"
            [color]="(themeService.theme$ | async)?.onBackgroundColor"
            (cardUnsaved)="closeModalIfUnsaved()"></kp-feedback-buttons>
        }
      </header>
      <section class="top-section">
        <h3>{{ localResource?.data?.programName }}</h3>
        <p class="provider">{{ t('by') }} {{ localResource?.data?.providerName }}</p>
      </section>
      @if (localResource?.status === status.done) {
        <kp-next-steps
          class="section middle-section button-container button-container-mobile next-step-container"
          [card]="localResource?.data"></kp-next-steps>
      } @else if (localResource?.status === status.starting) {
        <kp-shimmer class="button-shimmer"></kp-shimmer>
      } @else {
        <ng-container *ngTemplateOutlet="errorState"></ng-container>
      }
      <section class="section middle-section button-container next-step-container">
        <h3>{{ t('Next Steps') }}</h3>
        @if (localResource?.status === status.done) {
          <kp-next-steps [card]="localResource?.data"></kp-next-steps>
        } @else if (localResource?.status === status.starting) {
          <kp-shimmer class="button-shimmer"></kp-shimmer>
        } @else {
          <ng-container *ngTemplateOutlet="errorState"></ng-container>
        }
      </section>
      <section class="middle-section non-location-section">
        <h4>{{ t('Eligibility') }}</h4>
        @if (localResource?.status === status.done) {
          <ul>
            @for (rule of localResource?.data?.rules; track rule) {
              <li>{{ rule }}</li>
            } @empty {
              <li>
                {{ t('Follow the next steps to find out if this program has eligibility criteria.') }}
              </li>
            }
          </ul>
        } @else {
          @if (localResource?.status === status.starting) {
            <kp-shimmer class="details-shimmer"></kp-shimmer>
          } @else {
            <ng-container *ngTemplateOutlet="errorState"></ng-container>
          }
        }
      </section>
      <section class="middle-section non-location-section">
        <h4>{{ t('Description') }}</h4>
        @if (localResource?.status === status.done) {
          <div class="description" [innerHTML]="localResource?.data?.description"></div>
        } @else if (localResource?.status === status.starting) {
          <kp-shimmer class="details-shimmer"></kp-shimmer>
        } @else {
          <ng-container *ngTemplateOutlet="errorState"></ng-container>
        }
      </section>
      <section class="middle-section location-section">
        <svg-icon class="svg-icon location-svg hidden-print" src="/assets/icons/location.svg"></svg-icon>
        <h3 class="hidden-print">{{ t('Nearest Location') }}</h3>
        @if (localResource?.status === status.done) {
          <kp-local-resource-location
            [office]="localResource?.data?.offices[0]"
            [websiteUrl]="localResource?.data?.websiteUrl"></kp-local-resource-location>
          @if (localResource?.data?.offices[1] && !otherLocationsShown) {
            <button class="button button-pill other-locations-button hidden-print" (click)="showOtherLocations()">
              {{ t('See other nearby locations') }}
            </button>
          }
          @if (otherLocationsShown || isPrint) {
            @for (office of localResource?.data?.offices | slice: 1; track office) {
              <kp-local-resource-location [office]="office"></kp-local-resource-location>
            }
          }
        } @else {
          @if (localResource?.status === status.starting) {
            <kp-shimmer class="office-shimmer"></kp-shimmer>
          } @else {
            <ng-container *ngTemplateOutlet="errorState"></ng-container>
          }
        }
        @if (localResource?.status === status.done && localResource?.data?.updateDate) {
          <p class="update-date">
            <kp-branding-icon class="hidden-screen" [product]="product"></kp-branding-icon>
            {{ t('Program information last updated') }}
            {{ localResource?.data?.updateDate }}
          </p>
        }
        <p class="attribution hidden-screen">{{ t('Powered by') }} findhelp</p>
      </section>
    </div>
  }

  <ng-template #errorState>
    <div class="error-container">
      <span class="error-title-container">
        <svg-icon src="/assets/icons/alert-circle.svg"></svg-icon>
        {{ t('Something went wrong.') }}
      </span>
      <p>
        {{ t('Please click on the resource again or reach out to') }}
        <a href="mailto:support@kumanu.com" target="_blank">support&#64;kumanu.com</a>
        {{ t('if the problem persists.') }}
      </p>
    </div>
  </ng-template>
</ng-container>
