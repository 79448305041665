<ng-container *transloco="let t">
  <div class="title-container">
    <svg-icon class="icon" src="/assets/icons/question-filled-circle.svg"></svg-icon>
    <h1 class="title">{{ t('Help') }}</h1>
  </div>
  <p class="description">{{ t('Need help now? Access support and resources below.') }}</p>
  <ng-content></ng-content>
  <div class="help-accordion-container">
    <a class="link-help-center" href="https://support.kumanu.com" target="_blank">
      {{ t('productName Help Center', { productName }) }}
      <svg-icon src="/assets/icons/expand.svg" class="icon-external-link"></svg-icon>
    </a>
    <kui-accordion>
      <kui-accordion-group
        key="purposefulTechnicalSupport"
        [initialStateOpen]="false"
        [toggleText]="t('Technical Support')">
        <h3 class="accordion-title" acc-open>{{ t('Technical Support') }}</h3>
        <!-- Using prettier ignore comment here to keep the period aligned with the end of the sentence without a space -->
        <!-- prettier-ignore -->
        <span acc-collapsible>
          {{ t('Let us know how we can help. Get in touch with technical support anytime by emailing') }}
          <a
            class="help-link"
            data-focusable
            href="mailto:support@kumanu.com?Subject={{ productName }}%20Support%20Request"
            target="_blank">
            support&#64;kumanu.com
          </a>.
        </span>
      </kui-accordion-group>
    </kui-accordion>
  </div>
  <h2 class="title-emergency">{{ t('Emergency Hotlines') }}</h2>
  <div class="help-accordion-container">
    <kui-accordion>
      <kui-accordion-group
        key="crisisLifeline"
        [initialStateOpen]="false"
        [toggleText]="'988 ' + t('Suicide and Crisis Lifeline')">
        <h3 class="accordion-title" acc-open>988 {{ t('Suicide and Crisis Lifeline') }}</h3>
        <!-- Using prettier ignore comment here to keep the period aligned with the end of the sentence without a space -->
        <!-- prettier-ignore -->
        <span acc-collapsible>
          {{
            t(
              'If you are thinking about suicide or self-harm, are worried about a friend or loved one, or need some emotional support, call or text the Suicide and Crisis Lifeline at'
            )
          }}
          <a class="help-link" data-focusable [href]="'988' | clickableTelephoneNumber" aria-label="call or text 988" target="_blank">988</a>.
          {{ t('You can also chat online at') }}
          <a class="help-link" data-focusable href="https://988lifeline.org/" target="_blank">988lifeline.org</a>.
          {{ t('Lifeline is free, confidential, and available 24 / 7 across the United States.') }}
        </span>
      </kui-accordion-group>
      <kui-accordion-group
        key="domesticViolenceHotline"
        [initialStateOpen]="false"
        [toggleText]="t('Domestic Violence Hotline')">
        <h3 class="accordion-title" acc-open>{{ t('Domestic Violence Hotline') }}</h3>
        <!-- Using prettier ignore comment here to keep the period aligned with the end of the sentence without a space -->
        <!-- prettier-ignore -->
        <span acc-collapsible>
          {{
            t(
              'If you or anyone you know are in crisis or danger, or need immediate assistance, please use the following resource to get help in the United States. National Domestic Violence Hotline,'
            )
          }}
          <a class="help-link" data-focusable [href]="'+1-800-799-7233' | clickableTelephoneNumber" aria-label="call 800-799-7233" target="_blank">
            800-799-7233
          </a>.
        </span>
      </kui-accordion-group>
      <kui-accordion-group
        key="distressHelpline"
        [initialStateOpen]="false"
        [toggleText]="t('Disaster Distress Helpline')">
        <h3 class="accordion-title" acc-open>{{ t('Disaster Distress Helpline') }}</h3>
        <!-- Using prettier ignore comment here to keep the period aligned with the end of the sentence without a space -->
        <!-- prettier-ignore -->
        <span acc-collapsible>
          {{
            t(
              'If you or anyone you know has experienced a disaster, contact the Disaster Distress Helpline. The Helpline is a national hotline available 24/7, 365 days a year, dedicated to providing immediate crisis counseling for people who are experiencing emotional distress related to any natural or human-caused crisis or disaster. Disaster Distress Helpline,'
            )
          }}
          <a class="help-link" data-focusable [href]="'+1-800-985-5990' | clickableTelephoneNumber" aria-label="call 800-985-5990" target="_blank">
            800-985-5990
          </a>.
        </span>
      </kui-accordion-group>
      <kui-accordion-group key="hungerHotline" [initialStateOpen]="false" [toggleText]="t('Hunger Hotline')">
        <h3 class="accordion-title" acc-open>{{ t('Hunger Hotline') }}</h3>
        <!-- Using prettier ignore comment here to keep the period aligned with the end of the sentence without a space -->
        <!-- prettier-ignore -->
        <span acc-collapsible>
          {{
            t(
              'If you or someone close to you is currently experiencing hunger, call the National Hunger Hotline. This hotline can help provide information about local food pantries, government programs, and other organizations. National Hunger Hotline,'
            )
          }}
          <a class="help-link" data-focusable [href]="'+1-866-348-6479' | clickableTelephoneNumber" aria-label="call 866-348-6479" target="_blank">
            866-348-6479
          </a>.
        </span>
      </kui-accordion-group>
      <kui-accordion-group key="veteranHotline" [initialStateOpen]="false" [toggleText]="t('Veterans Hotline')">
        <h3 class="accordion-title" acc-open>{{ t('Veterans Hotline') }}</h3>
        <!-- Using prettier ignore comment here to keep the period aligned with the end of the sentence without a space -->
        <!-- prettier-ignore -->
        <span acc-collapsible>
          {{ t('For immediate help, contact the Nation Veterans Crisis Line. Call') }}
          <a class="help-link" data-focusable [href]="'988' | clickableTelephoneNumber" aria-label="call 988" target="_blank">988</a>
          {{ t('and press 1. Text') }}
          <a class="help-link" data-focusable [href]="'838255' | clickableTelephoneNumber : null :true" aria-label="text 838255" target="_blank">838255</a>.
          <a class="help-link" data-focusable href="https://veteranscrisisline.net/get-help-now/chat/" target="_blank">
            {{ t('Start a confidential chat') }}</a>.
          {{ t('For TTY, call') }}
          <a class="help-link" data-focusable [href]="'711' | clickableTelephoneNumber" aria-label="TTY call 711. then enter. 988" target="_blank">711
            {{ t('then') }}
            988</a>.
        </span>
      </kui-accordion-group>
    </kui-accordion>
  </div>
  <kui-version-tag class="version-tag"></kui-version-tag>
  <p class="copyright">
    <kp-copyright [includeProMedica]="false" [includeKumanuTrademark]="isPurposeful"></kp-copyright>
  </p>
  <p class="site-map-link-wrap">
    <a [routerLink]="['/site-map']" class="site-map-link">{{ t('Site Map') }}</a>
  </p>
</ng-container>
