import { computed, signal } from '@angular/core';
import { Answer } from '@kp/question-set/models/answer.model';
import { QuestionDisplay } from '@kp/question-set/models/question-display.model';
import { QuestionSettings } from '@kp/question-set/models/question-settings.model';
import { Deserializable, JsonObject, Serializable } from '@ktypes/models';

export enum QuestionType {
  checkbox = 'checkbox',
  image = 'image',
  image_icon_selection = 'image_icon_selection',
  image_tile = 'image_tile',
  image_word_list = 'image_word_list',
  likert = 'likert',
  long_text = 'long_text',
  short_text = 'short_text',
  slider = 'slider',
  radio_button = 'radio_button',
  theme = 'theme',
  time_picker = 'time_picker',
  word_list = 'word_list',
}

export const questionTypeText: QuestionType[] = [QuestionType.long_text, QuestionType.short_text];
export const questionTypeSingleOption: QuestionType[] = [
  QuestionType.likert,
  QuestionType.radio_button,
  QuestionType.slider,
];
export const questionTypeMultipleOptions: QuestionType[] = [
  QuestionType.checkbox,
  QuestionType.image_icon_selection,
  QuestionType.image_tile,
  QuestionType.image_word_list,
  QuestionType.word_list,
];
export const questionTypeList: QuestionType[] = [...questionTypeSingleOption, ...questionTypeMultipleOptions];
export const questionTypeImage: QuestionType[] = [QuestionType.image];
export const questionTypeDateTime: QuestionType[] = [QuestionType.time_picker];
export const questionTypeTheme: QuestionType[] = [QuestionType.theme];

export enum DataType {
  ordinal = 'ordinal',
  numeric = 'numeric',
}

export enum QuestionLogicKey {
  best_self_question_v1 = 'best-self-question-v1',
  life_purpose_question_v1 = 'onboarding-purpose_question-v1',
  reflection_intention_question_2 = 'reflection_intention_question_2',
  roles_question_v1 = 'onboarding-roles_question-v1',
  work_purpose_question_v1 = 'work-purpose-question-v1',
}

export class Question implements Deserializable<JsonObject, Question>, Serializable<JsonObject> {
  constructor(
    public id?: string,
    public display?: QuestionDisplay,
    public settings?: QuestionSettings,
    public logicKey?: string | QuestionLogicKey,
    public refKey?: string,
    inputType?: QuestionType,
    dataType?: DataType,
    public answer?: Answer,
    public questionSetId?: string
  ) {
    this.dataType.set(dataType);
    this.inputType.set(inputType);
  }

  inputType = signal<QuestionType>(null);
  isTextType = computed<boolean>(() => {
    return questionTypeText.includes(this.inputType());
  });
  isSingleOptionType = computed<boolean>(() => {
    return questionTypeSingleOption.includes(this.inputType());
  });
  isMultipleOptionType = computed<boolean>(() => {
    return questionTypeMultipleOptions.includes(this.inputType());
  });
  isListType = computed<boolean>(() => {
    return questionTypeList.includes(this.inputType());
  });

  dataType = signal<DataType>(null);
  isNumeric = computed<boolean>(() => {
    return this.dataType() === DataType.numeric;
  });
  isOrdinal = computed<boolean>(() => {
    return this.dataType() === DataType.ordinal;
  });

  get hasAnswer() {
    const answerValues = this.answer?.values || [];
    const hasAnswerValues = answerValues.filter((answerValue) => answerValue != null).length > 0;
    const isMultipleChoice = [QuestionType.checkbox, QuestionType.image_word_list, QuestionType.word_list].includes(
      this.inputType()
    );
    let hasMultipleChoiceAnswersAndMatchingOption = false;
    if (isMultipleChoice) {
      const selected = this.answer?.values?.filter((ans) => (ans?.value as boolean) === true) || [];
      const hasMultipleChoiceAnswers = answerValues.some((answerValue) => answerValue?.value);
      const hasMatchingMultipleChoiceOption = this.display?.options?.some((option) =>
        selected.some((ans) => ans?.optionId === option.id)
      );
      hasMultipleChoiceAnswersAndMatchingOption = hasMultipleChoiceAnswers && hasMatchingMultipleChoiceOption;
    }
    return (
      hasAnswerValues && ((isMultipleChoice && hasMultipleChoiceAnswersAndMatchingOption) || answerValues[0]?.value)
    );
  }

  deserialize(input: JsonObject): Question {
    if (input == null) {
      return null;
    }
    this.id = input['id'] as string;
    this.display = new QuestionDisplay().deserialize(input['display']);
    this.settings = new QuestionSettings().deserialize(input['settings']);
    this.logicKey = input['logicKey'] as string;
    this.refKey = input['refKey'] as string;
    this.inputType.set(input['inputType'] as QuestionType);
    this.dataType.set(input['dataType'] as DataType);
    this.answer = new Answer().deserialize(input['answer'] ?? input['previousAnswer']);

    const values = this.answer?.values;
    if (values) {
      for (const option of this.display?.options ?? []) {
        if (
          values.find((answer) => {
            return answer.optionId === option.id && answer.value === true;
          })
        ) {
          option.isSelected = true;
        }
      }
    }

    return this;
  }

  serialize(): JsonObject {
    const json: JsonObject = {};
    json['id'] = this.id;
    json['display'] = this.display?.serialize();
    json['settings'] = this.settings?.serialize();
    json['logicKey'] = this.logicKey;
    json['refKey'] = this.refKey;
    json['inputType'] = this.inputType();
    json['dataType'] = this.dataType();
    json['answer'] = this.answer?.serialize();
    return json;
  }
}
