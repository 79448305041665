import { inject, Inject, Injectable } from '@angular/core';
import { FirebaseApp } from '@angular/fire/app';
import { Messaging } from '@angular/fire/messaging';
import { areCookiesEnabled, isIndexedDBAvailable } from '@firebase/util';
import { EnvironmentVariablesService } from '@kenv';
import { NotificationsApi } from '@kp/notifications/notifications.api';
import { Constants } from '@kp/shared/constants.service';
import { WINDOW } from '@kservice';
import { CardEventType } from '@ktypes/enums';
import { DataStatus, JsonObject, NotificationDevice, NotificationInfo, Status } from '@ktypes/models';
import { DateTimeUtil } from '@kutil';
import { getMessaging, getToken } from 'firebase/messaging';
import { BehaviorSubject, combineLatest, from, Observable, of, Subject } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

export enum NotificationPermissionStatus {
  enabled = 'enabled',
  notNow = 'notNow',
}

export enum NotificationPermissionState {
  default = 'default',
  granted = 'granted',
  denied = 'denied',
}

export const FIREBASE_CLOUD_MESSAGING_PUSH_SCOPE = '/firebase-cloud-messaging-push-scope';
export const FIREBASE_CLOUD_MESSAGING_SERVICE_WORKER_FILENAME = '/firebase-messaging-sw.js';

// not using @firebase/messaging isSupported (isWindowSupported) method because it is async
export const hasNotificationSupport =
  typeof window !== 'undefined' &&
  isIndexedDBAvailable() &&
  areCookiesEnabled() &&
  'serviceWorker' in navigator &&
  'PushManager' in window &&
  'Notification' in window &&
  'fetch' in window &&
  Object.prototype.hasOwnProperty.call(ServiceWorkerRegistration.prototype, 'showNotification') &&
  Object.prototype.hasOwnProperty.call(PushSubscription.prototype, 'getKey');

@Injectable({
  providedIn: 'root',
})
export class NotificationsBloc {
  private _firebaseApp = hasNotificationSupport ? inject(FirebaseApp) : null;

  constructor(
    private _environmentVariablesService: EnvironmentVariablesService,
    private _notificationsApi: NotificationsApi,
    @Inject(WINDOW) private _window: Window
  ) {
    try {
      if (this._firebaseApp != null) {
        this._messaging = getMessaging(this._firebaseApp);
        if (this._messaging && _window.Notification?.permission === NotificationPermissionState.granted) {
          const fcmToken$ = combineLatest([
            this._currentFcmToken$?.pipe(map((currentFcmToken) => currentFcmToken ?? '')) ?? of(''),
            from(
              // runs once on NotificationBloc creation
              this._getOrRegisterServiceWorker().then(this._getFirebaseToken.bind(this))
            ),
            this._devicesSubject$.pipe(
              filter((devicesSubject) => devicesSubject?.status === Status.done),
              map((devicesSubject) => devicesSubject?.data)
            ),
          ]);

          fcmToken$.pipe(take(1))?.subscribe(([currentFcmToken, updatedToken, devices]) => {
            if (
              devices?.some(
                (device) => device.platformName === Constants.platformName && device.registrationToken === updatedToken
              )
            ) {
              this._storeFirebaseTokenIfChanged(currentFcmToken, updatedToken);
            }
          });
        }
      }
    } catch (error) {
      console.warn('Error trying to instantiate Firebase Messaging', error);
    }

    // Handle incoming messages. Called when:
    // - a message is received while the app has focus
    // - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
    /*const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      console.log('In App Message received. ', payload);
    });*/
  }

  private _currentFcmToken$ = new BehaviorSubject<string>(null);
  private _devicesSubject$ = new BehaviorSubject<DataStatus<NotificationDevice[]>>(null);
  private _notificationPermission$ = new BehaviorSubject<NotificationPermission>(this._window.Notification?.permission);
  private _notificationSubject$ = new BehaviorSubject<DataStatus<NotificationInfo[]>>(null);
  private _updatedNotification$ = new Subject<NotificationInfo>();

  private readonly _messaging: Messaging;

  get notificationPermission$(): Observable<NotificationPermission> {
    return this._notificationPermission$.asObservable();
  }

  get notificationSubject$(): Observable<DataStatus<NotificationInfo[]>> {
    return this._notificationSubject$.asObservable();
  }

  get updatedNotification$(): Observable<NotificationInfo> {
    return this._updatedNotification$.asObservable();
  }

  get devicesSubject$(): Observable<DataStatus<NotificationDevice[]>> {
    return this._devicesSubject$.asObservable();
  }

  isSupported(): boolean {
    return hasNotificationSupport;
  }

  refresh(): void {
    this._notificationSubject$.next(new DataStatus<NotificationInfo[]>(Status.starting, null, null));
    this._notificationsApi.getNotifications().then((notificationInfo) => {
      this._notificationSubject$.next(
        new DataStatus<NotificationInfo[]>(notificationInfo ? Status.done : Status.error, null, notificationInfo)
      );
    });
  }

  updateNotification(notificationToUpdate: NotificationInfo): void {
    this._notificationsApi.updateNotification(notificationToUpdate).then((notification) => {
      this._updatedNotification$.next(notification);
      this.refresh();
    });
  }

  createNotification(notificationToCreate: NotificationInfo): void {
    this._notificationsApi.createNotification(notificationToCreate).then((notification) => {
      this._updatedNotification$.next(notification);
      this.refresh();
    });
  }

  deleteNotification(notificationToDelete: NotificationInfo): void {
    this._notificationsApi.deleteNotification(notificationToDelete).then(() => {
      this.refresh();
    });
  }

  handleCardNotificationUpdates(cardEventType: CardEventType, notification: NotificationInfo): void {
    switch (cardEventType) {
      case CardEventType.REMINDER_EDITED:
        if (notification?.id) {
          this.updateNotification(notification);
        } else {
          this.createNotification(notification);
        }
        break;
      case CardEventType.REMINDER_DELETED:
        if (!notification?.id) {
          return;
        }
        this.deleteNotification(notification);
        break;
    }
  }

  async requestPermission(): Promise<NotificationPermission> {
    try {
      // if permission is already granted, don't request again
      if (this._window.Notification?.permission === NotificationPermissionState.granted) {
        this.registerDevice(this._window.Notification.permission);
        return this._window.Notification.permission;
      }
      return this._window.Notification.requestPermission()?.then(
        (permission: NotificationPermission) => {
          if (permission === NotificationPermissionState.granted) {
            this.registerDevice(permission);
          } else {
            this._notificationPermission$.next(permission);
            console.warn('NotificationBloc: Notification requestPermission not granted');
          }
          return permission;
        },
        (error) => {
          console.warn('Unable to request Firebase token: ', error);
          return NotificationPermissionState.denied;
        }
      );
    } catch (error) {
      console.warn('Error trying to instantiate Firebase Messaging', error);
      const permission = this._window.Notification?.permission ?? NotificationPermissionState.denied;
      this._notificationPermission$.next(permission);
      return permission;
    }
  }

  registerDevice(permission: NotificationPermission): void {
    this._notificationPermission$.next(permission);
    (this._currentFcmToken$?.pipe(take(1)) ?? of('')).subscribe((currentFcmToken) => {
      this._getOrRegisterServiceWorker()
        .then(this._getFirebaseToken.bind(this))
        .then(this._storeFirebaseTokenIfChanged.bind(this, currentFcmToken))
        .catch((error) => {
          console.warn('getToken error ', error);
        });
    });
  }

  getDevices(backgroundUpdate = false): void {
    if (!backgroundUpdate) {
      this._devicesSubject$.next(new DataStatus<NotificationDevice[]>(Status.starting, null, null));
    }
    this._notificationsApi.getDevices().then((devices) => {
      this._devicesSubject$.next(new DataStatus<NotificationDevice[]>(Status.done, null, devices));
    });
  }

  deleteWebDevices(deviceList: NotificationDevice[]): void {
    for (const device of deviceList) {
      if (device.platformName === Constants.platformName) {
        void this._notificationsApi.deleteDevice(device.id);
      }
    }
    this._currentFcmToken$.next(null);
    const updatedList = deviceList.filter((device) => device.platformName !== Constants.platformName);
    this._devicesSubject$.next(new DataStatus<NotificationDevice[]>(Status.done, null, updatedList));
  }

  getSelectedDays(days: string[]): string {
    if (DateTimeUtil.everyDayAbbreviations().every((day) => days.includes(day))) {
      return 'Every Day';
    } else if (days?.length === 5 && DateTimeUtil.weekdaysAbbreviations().every((day) => days.includes(day))) {
      return 'Weekdays';
    } else if (days?.length === 2 && DateTimeUtil.weekendAbbreviations().every((day) => days.includes(day))) {
      return 'Weekends';
    } else {
      if (days.length === 1) {
        return days[0];
      } else {
        const capitalized = DateTimeUtil.everyDayAbbreviations()
          .filter((day) => days.includes(day))
          .map((day) => day);
        return capitalized.join(', ');
      }
    }
  }

  private _storeDevice(registrationToken: string) {
    // store it on your database for each user
    const json: JsonObject = {};
    json['notificationPlatform'] = Constants.platformName;
    json['registrationToken'] = registrationToken;
    this._notificationsApi.createDevice(json).then((userDevice) => {
      this._currentFcmToken$.next(registrationToken);
      this.getDevices(true);
    });
  }

  private _storeFirebaseTokenIfChanged(currentFcmToken: string, updatedToken: string) {
    // send updated token to server if it changes
    if (updatedToken && updatedToken !== currentFcmToken) {
      this._storeDevice(updatedToken);
    }
  }

  private _getFirebaseToken(serviceWorkerRegistration: ServiceWorkerRegistration) {
    return getToken(this._messaging, {
      vapidKey: this._environmentVariablesService.config.vapidKey as string,
      serviceWorkerRegistration,
    });
  }

  private _getOrRegisterServiceWorker() {
    // As of 8/19/24, tThe call to this method is necessary because a direct call to the Firebase getToken
    // method can fail if it's the first time as the service worker may not be installed yet; only way to
    // mitigate is to check for the serviceWorker existence first, get the serviceWorkerRegistration (or
    // register the service worker manually), and then pass that to the getToken call once it is known to
    // exist. See: https://github.com/firebase/firebase-js-sdk/issues/7693 for more details.
    if ('serviceWorker' in this._window.navigator) {
      return this._window.navigator.serviceWorker
        .getRegistration(FIREBASE_CLOUD_MESSAGING_PUSH_SCOPE)
        .then(this._handlePushScopeRegistration.bind(this));
    }
    throw new Error('The browser does not support service workers.');
  }

  private async _handlePushScopeRegistration(serviceWorkerRegistration: ServiceWorkerRegistration) {
    if (serviceWorkerRegistration) {
      return serviceWorkerRegistration;
    }
    return this._window.navigator.serviceWorker
      .register(FIREBASE_CLOUD_MESSAGING_SERVICE_WORKER_FILENAME)
      .then(this._handleServiceWorkerManualRegistration.bind(this))
      .catch((err) => {
        console.warn('manual Firebase Service Worker register failed', err);
      });
  }

  private _handleServiceWorkerManualRegistration(serviceWorkerRegistration: ServiceWorkerRegistration) {
    if (serviceWorkerRegistration) {
      return serviceWorkerRegistration;
    }
    throw new Error('Could not register service worker.');
  }
}
