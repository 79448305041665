<ng-container *transloco="let t">
  @if (navActive) {
    <div class="overlay" role="presentation" (click)="closeOverlay()" (keyup.escape)="closeOverlay()"></div>
  }

  <kui-a11y-live-announce politenessSetting="assertive" [announcement]="sideMenuAnnouncement"></kui-a11y-live-announce>
  <aside
    class="sidebar-nav"
    [ngClass]="{ active: navActive }"
    [attr.aria-hidden]="navActive ? null : true"
    [attr.tabindex]="navActive ? null : -1"
    (click)="onSidebarClick($event)"
    (keydown.enter)="onSidebarClick($event)">
    <nav class="nav-items" [attr.aria-hidden]="navActive ? null : true" [attr.tabindex]="navActive ? null : -1">
      @for (navItem of navBloc.sideNavSubject$ | async; track navItem; let first = $first) {
        @if (navItem.type !== navType.invites && navItem.type !== navType.challenges) {
          @if (!navItem.external) {
            <a
              [routerLink]="navItem.link"
              class="nav-item"
              [attr.aria-hidden]="navActive ? null : true"
              [attr.tabindex]="navActive ? null : -1"
              (keydown.shift.tab)="first ? sideNavTab($event) : true"
              routerLinkActive="nav-active"
              ariaCurrentWhenActive="page">
              <span class="nav-item-inner">
                <svg-icon [src]="navItem.icon" class="navIcon"></svg-icon>
                <span class="nav-item-text">{{ t(navItem.title) }}</span>
              </span>
            </a>
          }
          @if (navItem.external) {
            <a
              [href]="navItem.link"
              class="nav-item"
              (click)="closeOverlay()"
              target="_blank"
              [attr.aria-label]="navItem.title + ' - External Link'"
              [attr.aria-hidden]="navActive ? null : true"
              [attr.tabindex]="navActive ? null : -1"
              (keydown.shift.tab)="first ? sideNavTab($event) : true"
              routerLinkActive="nav-active"
              ariaCurrentWhenActive="page">
              <span class="nav-item-inner">
                <svg-icon [src]="navItem.icon" class="navIcon"></svg-icon>
                <span class="nav-item-text">{{ t(navItem.title) }}</span>
                <svg-icon src="/assets/icons/expand.svg" class="external-link-icon"></svg-icon>
              </span>
            </a>
          }
        }
        @if (navItem.type === navType.challenges) {
          @if (tagBloc.socialChallengeEnabled$ | async) {
            <a
              [routerLink]="navItem.link"
              class="nav-item"
              [attr.aria-hidden]="navActive ? null : true"
              [attr.tabindex]="navActive ? null : -1"
              (keydown.shift.tab)="first ? sideNavTab($event) : true"
              routerLinkActive="nav-active"
              ariaCurrentWhenActive="page">
              <span class="nav-item-inner">
                <svg-icon [src]="navItem.icon" ngClass="navIcon"></svg-icon>
                <span class="nav-item-text">{{ t(navItem.title) }}</span>
              </span>
            </a>
          }
        }
        @if (navItem.type === navType.invites) {
          @if ((sharingBloc.invites$ | async)?.length > 0) {
            <a
              [routerLink]="navItem.link"
              class="nav-item"
              [attr.aria-hidden]="navActive ? null : true"
              [attr.tabindex]="navActive ? null : -1"
              (keydown.shift.tab)="first ? sideNavTab($event) : true"
              routerLinkActive="nav-active"
              ariaCurrentWhenActive="page">
              <span class="nav-item-inner">
                <svg-icon [src]="navItem.icon" ngClass="navIcon"></svg-icon>
                <span class="nav-item-text">{{ t(navItem.title) }}</span>
              </span>
            </a>
          }
        }
      }

      @if (moreApps(); as apps) {
        @if (apps.length > 1) {
          <div class="more-apps">
            <span class="more-apps-title">{{ t('More by') }} Kumanu</span>
          </div>
          @for (app of apps; track app) {
            @if (!app?.currentApp) {
              <a
                [href]="app?.link"
                class="nav-item"
                [attr.aria-label]="t('appTitle External Link', { appTitle: app.title })"
                [attr.aria-hidden]="navActive ? null : true"
                [attr.tabindex]="navActive ? null : -1"
                (click)="trackEvent(app)"
                target="_blank">
                <span class="nav-item-inner">
                  <svg-icon [src]="app?.whiteIcon" class="navIcon"></svg-icon>
                  <span class="nav-item-text">{{ app?.title }}</span>
                  <svg-icon src="/assets/icons/expand.svg" class="external-link-icon"></svg-icon>
                </span>
              </a>
            }
          }
        }
      }
    </nav>

    <nav class="tertiary-nav" [attr.aria-hidden]="navActive ? null : true" [attr.tabindex]="navActive ? null : -1">
      <div class="policies-container">
        <a
          class="policy-item"
          routerLink="/privacy"
          [attr.aria-hidden]="navActive ? null : true"
          [attr.tabindex]="navActive ? null : -1"
          routerLinkActive="nav-active"
          ariaCurrentWhenActive="page">
          {{ t('Privacy') }}
        </a>
        <span class="policy-dot">&middot;</span>
        <a
          class="policy-item"
          routerLink="/eula"
          [attr.aria-hidden]="navActive ? null : true"
          [attr.tabindex]="navActive ? null : -1"
          routerLinkActive="nav-active"
          ariaCurrentWhenActive="page">
          {{ t('Acceptable Use') }}
        </a>
      </div>
      <div class="tertiary-line">
        <div class="tertiary-nav-inner">
          <span class="tertiary-nav-item-text">{{ (dataStore?.user$ | async)?.nickname }}</span>
          <a
            routerLink="/logout"
            class="tertiary-nav-item logout-link"
            (keydown.tab)="closeOverlay(true)"
            [attr.aria-hidden]="navActive ? null : true"
            [attr.tabindex]="navActive ? null : -1"
            routerLinkActive="nav-active"
            ariaCurrentWhenActive="page">
            {{ t('Sign Out') }}
          </a>
        </div>
      </div>
    </nav>
  </aside>
</ng-container>
